import { permissions } from '@/enums/permissions.enum';

let ordersTypes = [
  'restaurants',
  'stores',
  'medicines',
  'delivery'
];
export default [
  {
    title: 'dashboard',
    icon: 'home-icon',
    tagVariant: 'light-warning',
    route: 'dashboard',
    permissions: []
  },
  {
    title: 'orders.title',
    icon: 'orders-icon',
    tagVariant: 'light-warning',
    permissions: [permissions.readOrdersRestaurants, permissions.readOrdersStore, permissions.readOrdersMedicine, permissions.readOrdersDelivery],

    children: [
      {
        title: 'orders.restaurants',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'orders-restaurants',
        permissions: [permissions.readOrdersRestaurants]

      },
      {
        title: 'orders.store',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'orders-store',
        permissions: [permissions.readOrdersStore]

      },
      {
        title: 'orders.medicine',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'orders-medicine',
        permissions: [permissions.readOrdersMedicine]
      },
      {
        title: 'orders.delivery',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'orders-delivery',
        permissions: [permissions.readOrdersDelivery]
      }
    ]
  },
  {
    title: 'assign-orders',
    icon: 'nav-users-icon',
    tagVariant: 'light-warning',
    route: 'assign-orders',
    permissions: [permissions.readAssignedOrders]
  },
  {
    title: 'chat',
    icon: 'nav-chat-icon',
    tagVariant: 'light-warning',
    route: 'chat',
    permissions: [permissions.chat]
  },
  {
    title: 'ads',
    icon: 'nav-ads-icon',
    tagVariant: 'light-warning',
    route: 'ads',
    permissions: [permissions.readAds]
  },
  {
    title: 'users.title',
    icon: 'nav-users-icon',

    tagVariant: 'light-warning',
    route: 'users',

    permissions: [permissions.readClientsInfo, permissions.readClientsOrders, permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews, permissions.readDriversInfo, permissions.readDriversFinancials, permissions.readDriversOrders, permissions.readDriversReviews],

    children: [
      {
        title: 'users.clients',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'users-clients',
        permissions: [permissions.readClientsInfo, permissions.readClientsOrders]
      },
      {
        title: 'users.vendors',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'users-vendors',
        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews]
      },
      {
        title: 'users.drivers',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'users-drivers',
        permissions: [permissions.readDriversInfo, permissions.readDriversFinancials, permissions.readDriversOrders, permissions.readDriversReviews]
      }
    ]
  },


  {

    title: 'financial.title',
    icon: 'money-icon',
    tagVariant: 'light-warning',
    route: 'financial-all',
    permissions: [permissions.readFinancials],
    children: ordersTypes.map((type) => {
      return {
        title: `financial_${type}`,
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: `financial-${type}`
        // permissions: [permissions[`manage${type[0].toUpperCase() + type.slice(1)}Orders`]],
      };
    })

  },
  {

    title: 'statement',
    icon: 'nav-statement-icon',
    tagVariant: 'light-warning',
    route: 'statement',
    permissions: [permissions.readStatements]
  },
  {

    title: 'reports',
    icon: 'nav-reports-icon',
    tagVariant: 'light-warning',
    route: 'reports',
    permissions: [permissions.readFinancials]//todo:: change permission

  },

  {
    title: 'discounts',
    icon: 'coupons-icon',
    tagVariant: 'light-warning',


    permissions: [permissions.readDiscounts],
    children: [
      {
        title: 'discounts-platform',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'discounts',
        permissions: [permissions.readDiscounts]
      },
      {
        title: 'discounts-vendors',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'discounts-vendors',
        permissions: [permissions.readDiscounts]
      }
    ]
  },

  {
    title: 'featured.title',
    icon: 'featured-icon',
    tagVariant: 'light-warning',
    permissions: [permissions.readFeaturedRestaurants, permissions.readFeaturedStores, permissions.readFeaturedMedicine],

    children: [
      {
        title: 'featured.restaurants',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'featured-restaurants',
        permissions: [permissions.readFeaturedRestaurants]

      },
      {
        title: 'featured.store',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'featured-store',
        permissions: [permissions.readFeaturedStores]

      },
      {
        title: 'featured.medicine',
        icon: 'nav-other-icon',
        tagVariant: 'light-warning',
        route: 'featured-medicine',
        permissions: [permissions.readFeaturedMedicine]
      }

    ]
  },
  {
    title: 'reviews',
    icon: 'star-icon',
    tagVariant: 'light-warning',
    route: 'reviews',
    permissions: [permissions.readReviews]
  },
  {
    title: 'settings',
    icon: 'setting-icon',
    tagVariant: 'light-warning',
    route: 'settings',
    permissions: []
  }
];
